.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-top: 20px;
}

.left-side-nav{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.left-side-nav img{
    width: 100%;
    height: 50px;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  margin-left: 25px;

}

.nav-links li {
  margin-right: 10px;
  color: #000000;
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: -.32px;
    padding-left: 30px;
    

}

.right-side-nav {
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.divider {
  margin: 0 5px;
     color: #000000;
    font-size: 16px;
     font-weight: 500px;
    text-transform: capitalize;
    letter-spacing: -.32px;
}


/* Navbar.css */

/* Add styling for the mobile menu icon */
.menu-icon {
  display: none;
  cursor: pointer;
}

/* Styles for screens with a maximum width of 768px */
@media (max-width: 768px) {
  /* Hide the right-side navigation items */
  .left-side-nav{

    flex-direction: row;
  }
  .h-menu {
    position: absolute !important;
    color: var(--black) !important;
    top: 11rem !important;

    flex-direction: column !important;
    background: black !important;
    font-weight: 500 !important;
    gap: 2rem !important;
    padding: 3rem !important;
    border-radius: 10px !important;
    align-items: flex-start !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.05) !important;
    transition: all 300ms ease-in !important;
    z-index: 99 !important;
    width: 58px;
    
    
}
.nav-links li {
  color: white;
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: -.32px;
  padding: 10px;

}
  /* Show the mobile menu icon */
  .menu-icon {
    display: block;
    position: absolute;
    top: 148px;
    right: 18px;
  }


  /* Style mobile menu items */
  


  .nav-links {
    display: block;
   
  }
  .left-side-nav img {
    width: 100%;
    height: 33px;
}

    .right-side-nav{
      display: none;
    }
}
