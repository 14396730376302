.Detail_full_container{
padding: 40px;
margin-top: 70px;
}

.image_detail img{
    position: absolute;
    left: 500px;
    width: 640px;
}
.image_detail{
top: -150px;
position: relative;

}
.det_Detail_contianer{
    border: 2px solid #000000;
    height: 350px;
    background-color: #000000;
    background-image: linear-gradient(180deg, #4A4B4C 0%, var(#000000 ) 100%);
    padding-bottom: 35px;
    border-radius: 30px;


}

.detail_inside{
    margin-left: 100px;
    margin-top: 60px;
    margin-bottom: 30px;

    justify-content: space-around;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.detail_para{
    font-size: 22px;
    color: white;
    font-weight: lighter;
}

.det_heading{
    font-size: 50px;
    font-weight: 700;
    color: white;

}

.det_button button{
    width: 201px;
    height: 52px;
    border-radius: 17px;
    background:  linear-gradient(135deg,rgb(206, 76, 156) 0%,rgb(152,150,240) 100%);
    border:none;
    color: white;
}
.det_button{

}


@media (max-width: 768px){

    .det_heading {
        font-size: 30px;
        font-weight: 700;
        color: white;
    }
    .detail_para {
        font-size: 20px;
        color: white;
        font-weight: lighter;
    }

    .det_button button {
        width: 170px;
        height: 52px;
        border-radius: 17px;
        background: linear-gradient(135deg,rgb(206, 76, 156) 0%,rgb(152,150,240) 100%);
        border: none;
        color: white;
        margin-top: 25px;
        
    }

    .det_Detail_contianer {
        border: 2px solid #000000;
        height: 590px;
        background-color: #000000;
        background-image: linear-gradient(180deg, #4A4B4C 0%, var(#000000 ) 100%);
        padding-bottom: 35px;
        border-radius: 30px;
        width: 270px;
    }

    .detail_inside {
        margin-left: 20px;
        margin-top: 60px;
        margin-bottom: 30px;
        justify-content: space-around;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

    .image_detail img {
        position: absolute;
        width: 410px;
        left: -85px;
        top: 490px;
    }

}