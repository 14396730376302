.product_container{
    margin-top: 120px;
}

.heading_product_container{
    text-align: center;
    margin-bottom: 60px;
}
.product_head{
    font-size: 40px;
    font-weight: bold;
  
}
.product_p_tag{
    font-size: 20px;
    color: gray;

}


.product1 {
    width: 290px;
    border: 1px solid rgb(184, 181, 181);
    background-color: white;
    border-radius: 40px;
    margin-left: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.product1_img {
    display: flex;
    justify-content: center;
}
.product1_img img{
    width: 215px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.im_below-para{
font-weight: bold;
}
.left_par_img_below{
    font-size: 20px;
    font-weight: bold;
    padding-right: 39px;
    text-align: center;
    margin-left: 20px;
}
.text_below_para_img{
    margin-left: 20px;
    font-size: 20px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
}
.product1_button button{
    width: 250px;
    height: 48px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 20px;
    background-color: blue;
    border: 2px solid blue;
    color: white;
    font-weight: 400;
    
}
.product1_button{

    padding: 10px;
    height: 30px;
    padding-bottom: 35px;
    display: flex;
    justify-content: center;
}



/*-------product 2-----------*/

.product2 {
    width: 290px;
    border: 1px solid rgb(184, 181, 181);
    background-color: white;
    border-radius: 40px;
    margin-left: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}



/*-------product 3-----------*/

.product3 {
    width: 290px;
    border: 1px solid rgb(184, 181, 181);
    background-color: white;
    border-radius: 40px;
    margin-left: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}



/*-------product 4-----------*/

.product4 {
    width: 290px;
    border: 1px solid rgb(184, 181, 181);
    background-color: white;
    border-radius: 40px;
    margin-left: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}


/*--------------uper container of product--------------------*/

.uper_container{
    display: flex;
    justify-content: space-evenly;
}


/*--------------lower container of product--------------------*/

.lower_container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 90px;
  
}


@media (max-width:768px){
    .product_p_tag {
        font-size: 11px !important;
        color: gray !important;
    }
    .product_head {
        font-size: 25px !important;
        font-weight: bold !important;
    }
    .uper_container {
       display: flex !important;
       flex-direction: column !important;
       align-items: center;
    }
    .product1_img img {
        width: 158px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
    }

    .left_par_img_below {
        font-size: 15px !important;
    
    }

    .text_below_para_img {
        margin-left: 20px;
        font-size: 12px;
        color: gray;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .product1_button button {
        width: 148px;
        height: 40px;
        margin-bottom: 20px;
        font-size: 12px;
        border-radius: 20px;
        background-color: blue;
        border: 2px solid blue;
        color: white;
        font-weight: 400;
    }

    .product1,.product2,.product3,.product4 {
        width: 236px;
        border: 1px solid rgb(184, 181, 181);
        background-color: white;
        border-radius: 40px;
        margin-left: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        margin-top: 10px;
    }
    .product2,.product3,.product4 {
        margin-top: 50px;
    }

    .lower_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px !important;
    }

}

