.heading_vist{
    font-size: 27px;
    font-weight: 600;
}

.para_vist{
    font-size: 17px;
    font-weight: 200;
    padding-top: 6px;
    color: gray;
}


.heading_company{
    font-size: 27px;
    font-weight: 600;
}

.para_company{
    font-size: 17px;
    font-weight: 200;
    padding-top: 6px;
    color: gray;
}

.heading_contact{
    font-size: 27px;
    font-weight: 600;
}

.para_contact{
    font-size: 17px;
    font-weight: 200;
    padding-top: 6px;
    color: gray;
}


.heading_Address{
    font-size: 27px;
    font-weight: 600;
}

.para_Address{
    font-size: 17px;
    font-weight: 200;
    padding-top: 5px;
    color: gray;
}

.lower_container{
    margin-bottom: 20px;
}

.hr_footer{
width: 96%;
margin-top: 40px;

}

.Last_container{
    display: flex;
    justify-content: space-between;
    padding: 30px;
}
.Left_c{
  font-weight: lighter;
  font-size: 18px;
}
.Right_c{
font-weight: lighter;
font-size: 18px;
display: flex;

}

.lower_container_footer{
    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px !important;
}
@media (max-width:768px){
    .lower_container_footer {
        display: flex;
        flex-direction: column;
        margin-top: 50px !important;
        margin-left: 40px;
    }
    .image_container img{
        padding-bottom: 30px;
    }
    .Vist_link_container{
        padding-bottom: 30px;
    }
    .heading_vist {
        font-size: 27px;
        font-weight: 600;
        padding-bottom: 14px;
    }
    .Company_container{
        padding-bottom: 30px;
    }
    .heading_company{
        padding-bottom: 14px;
    }
     

    .heading_contact{
        padding-bottom: 14px;
    }
    .Address_container{
        padding-bottom: 30px;
    }
    .heading_Address{
        padding-bottom: 14px;
    }
    .Cantact_container{
        padding-bottom: 30px;
    }

    .Last_container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        align-items: center;
    }
}