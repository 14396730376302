
.full_below-box{
    display: flex;
}
.button_box-left button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
   background: linear-gradient(-24deg,rgb(255,245,203) 0%,rgb(182,227,212) 15%,rgb(51,167,181) 100%);
   display: flex;
   margin-left: 16px;
}
.leftbox-belowbox{
    background-color: #080D13;
    border-radius: 25px

}
.box-ptag-left-below{
    font-weight: 600;
    font-size: 17px;
    color: white;

}
.heading-tag-left-below{
    font-size: 28px;
    color: white;
}
.image_box_left img {
    width: 330px;
    position: relative;
    left: -12px;
    top: 70px;
}
.leftbox-belowbox{
    width: 285px;
    padding: 50px;
    height: 570px;
}

/* ======================left  side end===========================================*/
.uper_mid{
    border: 2px solid red;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 278px;


}
.lower_mid {
    border: 2px solid green;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 278px;
}
.midbox-below{
padding-top: 20px;

        width: 310px;
    padding: 50px;
    height: 570px;
    border-radius: 25px;
    border: 2px solid red;

}

.box-ptag-mid{
    font-weight: 600;
    font-size: 20px;
    color: white;
}
.heading-tag-mid{
    font-size: 46px;
    color: white;
}
.button_box-mid button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
    background-color: #080D13;
    display: flex;
    margin-left: 16px;
}
.image_box_mid img{
    width: 330px;
    position: relative;
   left: -40px;
   bottom: 160px;
}
.mid-det-baqi{
    margin-top: -166px;

}




/* ======================Right  side===========================================*/
.rightbox-below{
    width: 260px;
    padding: 50px;
    height: 570px;
}
.image_box_right img{
    width: 300px;
    position: relative;
    left: 0.3%;
    top: 60px;

    
}
.box-heading-right-heading{
    font-size: 34px;
    color: white;
}
.box-ptag-right-below{
    font-weight: 600;
    font-size: 17px;
    color: white;
}


.rightbox-below{
    background-color: #080D13;
    border-radius: 25px;
}
.button_box-right button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
   background: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);;
   display: flex;
   margin-left: 16px;
}


.full_below-box{

    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}