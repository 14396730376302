.Container_f{
    padding-top: 70px;
    padding-left: 70px;
    padding-right: 70px;
    

}
.lower_contaienr{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/macos-big-sur-apple-layers-fluidic-colorful-wwdc-stock-1920x1080-1455.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 40px;
}
.para_f{
    font-size: 30px;
    padding-top: 100px;
    padding-bottom: 50px;
    color: white;
}
.heading_f{
    font-size: 65px;
    font-weight: 500;
    padding-bottom: 50px;
    color: white;
    text-align: center;
}

.heading_f_b{
    font-weight: 300;

}
.input_field_f {
    width: 100%;
    display: flex;
justify-content: center;
align-items: center;
margin-bottom: 80px;

}
.input_field_f input {
    width: 70%;
    height: 78px;
    border-radius: 40px;
    border: none;
    background-color: transparent; /* Set the background color to transparent */
    border: 1.4px solid white;
    padding-left: 40px; /* Add padding around the input field */
}

.input_field_f input[type='text']::placeholder {
    color: rgba(209, 228, 235, 0.87);
    font-size: 20px;
    font-weight: 100;
}


/* INPUT FIELD 2 */


.input_field_f2 {
    width: 100%;
    display: flex;
justify-content: center;
align-items: center;
margin-bottom: 80px;
display:none

}
.input_field_f2 button {
    width: 70%;
    height: 78px;
    border-radius: 40px;
    border: none;
    background-color: transparent; /* Set the background color to transparent */
    border: 1.4px solid white;
   
}

.input_field_f2 button[type='text']::placeholder {
    color: rgba(209, 228, 235, 0.87);
    font-size: 20px;
    font-weight: 100;
}

@media (max-width:768px){
 .lower_contaienr{
    margin-top: 60px
 }

    .Container_f {
        padding: 21px;
    }

    .para_f{
        font-size:18px
    }

    .heading_f {
        font-size: 35px;
        font-weight: 500;
        padding-bottom: 50px;
        color: white;
        text-align: center;
    }

    .input_field_f {
        width: 100%;
        display: flex;
        margin-bottom: 24px;
    }
    .input_field_f input {
        width: 55%;
        height: 64px;
        border-radius: 40px;
        border: none;
        background-color: transparent;
        border: 1.4px solid white;
        padding-left: 40px;
    }
    .input_field_f2 {
        width: 100%;
        display: flex;

    margin-bottom: 80px;
    
    
    }

    .input_field_f2 button {
        width: 66%;
        height: 67px;
        border-radius: 40px;
        border: none;
        background-color: transparent;
        border: 1.4px solid white;
        background: white;
        font-size: 20px;
        align-items: center;
        text-align:center;
        color: red;
    }
}
