.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1F2220;
  color: white;
  width: 100%;
  padding: 18px 0;
}

.left-side {
  display: flex;
  align-items: center;
}

.right-side {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 15px;
}

.text {
  font-size: 20px;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-left: 70px;
}
.contact-info-right{
  display: flex;
  align-items: center;
  margin-left: 70px;
}

.info {
  font-size: 20px;
}

.icon img {
  width: 20px;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .left-side {
    margin-bottom: 10px;
    text-align: center;
    padding-bottom: 12px;
  }
.icon img{
  width: 16px;
}
  .icon{
    width: 30px;
  height: 22px;
  margin-right: 0px;}

  .left-side .icon img {
    width: 22px;
    height: 22px;
  }

  .left-side .text span {
    font-size: 14px; /* Adjust the font size for smaller screens */
    margin-left: 0; /* Remove margin between icon and text for smaller screens */
  }

  .contact-info {
    margin-left: 0; /* Remove the left margin for smaller screens */
    text-align: center;
  }
  .contact-info-right {
    margin-left: 0; /* Remove the left margin for smaller screens */
    text-align: center;
  }

  .info {
    font-size: 12px; /* Adjust the font size for smaller screens */
  }

}
