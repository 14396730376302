.two_box_container {
  display: flex;
  gap: 40px;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 70px;
}
.two_box_img img {
  width: 250px;
  position: absolute;
  left: 392px;
  z-index: -1;
}
.left_side_two_box {
  width: 100%;
  background-color: black;
  z-index: -1;
  border-radius: 23px;
}
.two_box_detail {
  padding-left: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.two_box-ptag-left {
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.two_box_heading-tag-left {
  font-size: 34px;
  color: white;
}
.two_box_button_left button {
  color: white;
  font-weight: 700;
  border-radius: 30px;
  padding: 17px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  background: linear-gradient(
    -24deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 15%,
    rgb(51, 167, 181) 100%
  );
  display: flex;
  margin-left: 16px;
  border: none;
}

/*___________right side ----------------------*/

.right_side_two_box {
  width: 100%;
  background-color: #0c19b7;
  z-index: -1;
  border-radius: 23px;
}

.two_box_img_right img {
  width: 250px;
  position: absolute;
  z-index: -1;
  right: 104px;
}
.two_box_detail_right {
  padding-left: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.two_box-ptag-right {
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.two_box_heading-tag-right {
  font-size: 34px;
  color: white;
}

.two_box_button_right button {
  color: #0c19b7;
  font-weight: 700;
  border-radius: 30px;
  padding: 17px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  background-color: #fcb900;
  border: none;
  display: flex;
  margin-left: 16px;
}


@media (max-width:768px){
  html{
    padding:0 !important;
    margin:0 !important;
  }
  .two_box_container {
    display: flex;
    flex-direction:column;
    gap: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: 70px;
    align-items:center;
}

.left_side_two_box {
  width: 50%;
  background-color: black;
  padding-top: 34px;
  z-index: -1;
  border-radius: 23px;
  padding-right: 34px;
  padding-left: 34px;
}
.two_box_img img {
  width: 190px;
  position: absolute;
  left: 124px;
  z-index: -1;
}
.two_box_detail {
  padding-left: 0;
  padding-bottom: 100px;
}
.two_box-ptag-left {
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.two_box_heading-tag-left {
  font-size: 18px;
  color: white;
}

.two_box_button_left button {
  color: white;
  font-weight: 700;
  border-radius: 30px;
  padding: 17px;
  margin-top: 30px;
  background: linear-gradient( -24deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 15%, rgb(51, 167, 181) 100% );
  display: flex;
  margin-left: 16px;
  border: none;
}
.two_box_detail {
  padding-left: 0;
  padding-bottom: 30px;
  margin-top: 30px;
}

/* Right side responsive  */

.right_side_two_box {
  background-color: #0c19b7;
  width: 50%;
  padding-top: 34px;
  z-index: -1;
  border-radius: 23px;
  padding-right: 34px;
  padding-left: 34px;
  margin-top: 50px;
}
.two_box_img_right img {
  width: 156px;
  position: absolute;
  z-index: -1;
  right: 60px;
}
.two_box_detail_right {
  padding-top: 100px;
  padding-left: 0;
  padding-bottom: 30px;
  margin-top: 30px;
}

.two_box-ptag-right {
  font-weight: 600;
  font-size: 10px;
  color: white;
}

.two_box_heading-tag-right {
  font-size: 18px;
  color: white;
}

.two_box_button_right button {
  color: #0c19b7;
  font-weight: 700;
  border-radius: 30px;
  padding: 17px;
  margin-top: 30px;
  background-color: #fcb900;
  border: none;
  display: flex;
  margin-left: 16px;
}




}