.Blog1_container{
    border: 1px solid #cfbcbc;
    width:230px;
    padding:30px;
    border-radius: 20px;
}
.Blog_heading{
    display: flex;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 80px;

}

.date{
    font-size:18px;
    font-weight:400;
    margin-left: 20px;
}

.para{
    font-size:24px;
    font-weight:600;
    margin-top:10px;
}

.hr_blog{
    height: 3.8px;
    border-radius: 20px;
    display: flex;
    width: 80%;
    background-color: blue;
    margin-top: 80px;
}
.read_more{
    font-size:18px;
    font-weight:500;
    padding-top:20px;
    padding-bottom: 40px;
}

.Blog_container_full{
                                 
}


.Blog_container{
    display: flex;
    justify-content: space-evenly;
}


@media (max-width:768px){

    .Blog_container {
        display: flex;

        flex-direction:column !important;
        align-items:center;

    }
    .Blog1_container {
        border: 1px solid #cfbcbc;
        width: 225px;
        padding: 22px;
        border-radius: 20px;
    }
    .b2_c,.b3_c,.b4_c{  
        margin-top:40px

    }
    .Blog_container_full {
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
    }
}
