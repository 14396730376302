
.full_box{
    display: flex;
}
.button_box-left button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
   background: linear-gradient(-24deg,rgb(255,245,203) 0%,rgb(182,227,212) 15%,rgb(51,167,181) 100%);
   display: flex;
   margin-left: 16px;
}
.leftbox{
    background-color: #080D13;
    border-radius: 25px

}
.box-ptag-left{
    font-weight: 600;
    font-size: 20px;
    color: white;

}
.heading-tag-left{
    font-size: 46px;
    color: white;
}
.image_box_left img {
    width: 330px;
    position: relative;
    left: -12px;
    top: 70px;
}
.leftbox{
    width: 310px;
    padding: 50px;
    height: 570px;
}

.midbox{
    
    
        width: 310px;
    padding: 50px;
    height: 570px;
    border-radius: 25px;
    background-color: #ff6900;
}

.box-ptag-mid{
    font-weight: 600;
    font-size: 20px;
    color: white;
}
.heading-tag-mid{
    font-size: 46px;
    color: white;
}
.button_box-mid button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
    background-color: #080D13;
    display: flex;
    margin-left: 16px;
}
.image_box_mid img{
    width: 330px;
    position: relative;
   left: -40px;
   bottom: 160px;
}
.mid-det-baqi{
    margin-top: -166px;

}



.rightbox{
    width: 310px;
    padding: 50px;
    height: 570px;
}
.image_box_right img{
    width: 300px;
    position: relative;
    left: 0.3%;
    top: 60px;

    
}
.box-heading-right{
    font-size: 46px;
    color: white;
}
.box-ptag-right{
    font-weight: 600;
    font-size: 20px; 
    color: white;
}


.rightbox{
    background-color: #080D13;
    border-radius: 25px;
}
.button_box-right button{
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
   background: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);;
   display: flex;
   margin-left: 16px;
}


.full_box{

    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    
}

@media (max-width: 768px) {
  
    .full_box {
        display: flex;
        justify-content: space-evenly;
        margin-top: 50px;
        flex-direction: column;
        padding: 0 !important;
        justify-content: center;
        align-items: center;
    }
    .leftbox {
        width: 60%;
        padding: 12px;
        height: 340px;
        margin-bottom: 60px;
    
    }
    .heading-tag-left {
        font-size: 22px;
        color: white;
    }

    .button_box-left button {
        font-weight: 100 !important;
        padding: 12px !important;
        margin-top: 30px !important;
        display: flex !important;
        margin-left: -1px !important;
        font-size: 12px;
    }

    .image_box_left img {
        width: 189px !important;
        position: relative !important;
        left: 30px !important;
        top: 10px !important;
    }


    /*_________________Mid box*__________________*/
    .midbox {
        width: 44%;
        border-radius: 25px;
        background-color: #ff6900;
        padding: 50px;
        height: 293px;
        margin-bottom: 60px;
    }

    .image_box_mid img {
        width: 189px !important;
        position: relative !important;
        left: 30px !important;
        bottom: 130px !important;
    }

    .box-ptag-mid {
        font-weight: 600 !important;
        font-size: 12px !important;
        color: white !important;
    }

    .heading-tag-mid {
        font-size: 24px !important;
        color: white;
    }

    .button_box-mid button {
        color: white;
        font-weight: 700 !important;
        border-radius: 30px !important;
        padding: 14px !important;
        margin-top: 30px !important;
        background-color: #080D13;
        display: flex !important;
        margin-left: 16px !important;
        font-size: 12px !important;
    }



    /*================Right side ==========================*/
    .rightbox {
        width: 44% !important;
        padding: 50px !important;
        height: 293px !important;
        margin-bottom: 34px !important;
    }

    .box-ptag-right {
        font-weight: 600 !important;
        font-size: 12px !important;
        color: white;
    }

    .box-heading-right {
        font-size: 20px !important;
        color: white;
    }

    .button_box-right button {
        color: white;
        font-weight: 700 !important;
        border-radius: 30px !important;
        padding: 12px !important;
        margin-top: 14px !important;
        background: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
        display: flex !important;
        margin-left: 16px !important;
        font-size: 10px !important;
    }

    .image_box_right img {
        width: 189px !important;
        position: relative !important;
        left: -5.7% !important;
        top: 25px !important;
    }


}